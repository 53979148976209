if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initFrontendScripts)
} else {
    initFrontendScripts()
}

function initFrontendScripts() {
    setTimeout(() => {
        if (!!document.querySelector(".slider")) import("@/blade-components/slider")
        if (!!document.querySelector(".wp-block-tutkit-accordeon-with-images")) import("@/laraberg_frontend/accordeon-with-images")
        if (!!document.querySelector(".wp-block-tutkit-quote-slider")) import("@/laraberg_frontend/quote-slider")
        if (!!document.querySelector(".wp-block-tutkit-categories-overview")) import("@/laraberg_frontend/categories-overview")
        if (!!document.querySelector(".wp-block-tutkit-categories-with-icons")) import("@/laraberg_frontend/categories-with-icons")
        if (!!document.getElementById("price-models-app")) import("@/laraberg_frontend/price-module")
        if (!!document.querySelector(".wp-block-tutkit-image-slider")) import("@/laraberg_frontend/image-slider")
        if (!!document.querySelector(".wp-block-tutkit-video-with-texts")) import("@/laraberg_frontend/video-with-texts")
        if (!!document.querySelector(".wp-block-tutkit-video-with-poster")) import("@/laraberg_frontend/video-with-poster")
        if (!!document.querySelector(".wp-block-tutkit-anchors-auto")) import("@/laraberg_frontend/anchors-auto")
        if (!!document.getElementById("trainer-list-app")) import("@/laraberg_frontend/trainer-list")
        if (!!document.querySelector(".tk-email-form, .test-account")) import("@/laraberg_frontend/email")
        if (!!document.querySelector(".professional-purposes-form")) import("@/laraberg_frontend/email-tour")
        if (!!document.querySelector(".wp-block-tutkit-content-module-overview")) import("@/laraberg_frontend/content-module-overview")
        if (!!document.getElementById("openCompanyPlan")) import("@/laraberg_frontend/company-plan")
        if (!!document.querySelector(".wp-block-tutkit-hero-with-video")) import("@/laraberg_frontend/hero-video")
        if (!!document.querySelector(".wp-block-tutkit-newsletter-form.newsletter-form .checkbox.blade")) import("@/laraberg_frontend/newsletter-form")
        if (!!document.querySelector(".wp-block-tutkit-products-slider")) import("@/laraberg_frontend/products-slider")
        if (!!document.querySelector(".download-button")) import("@/laraberg_frontend/download-press-kit")
        if (!!document.querySelector(".wp-block-tutkit-google-reviews-slider")) import("@/laraberg_frontend/google-review-slider")
        if (!!document.querySelector(".wp-block-tutkit-new-packages-grid")) import("@/laraberg_frontend/new-packages-grid")
        if (!!document.querySelector("iframe[data-src]")) import("@/laraberg_frontend/youtube")
        if (!!document.getElementById("category-details")) import("@/frontend/category-details")
        if (!!document.getElementById("category-details")) import("@/frontend/category-custom")
        if (!!document.querySelector("#compatibility-popup-container")) import("@/frontend/content-card-download-button")
        if (!!document.querySelector(".wp-block-tutkit-custom-faq")) import("@/laraberg_frontend/faq")
        if (!!document.querySelector(".open-painting-app")) import("@/painting-app/main")
        if (!!document.querySelector(".open-image-editor-app")) import("@/image-editor-app/main")
    }, 1000)
}
